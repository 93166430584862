import React from 'react'
import {
  Button,
  FadeIn,
  Heading,
  match,
  NoWrap,
  P,
  reduceImages,
  Section,
  Link,
  ThemeProvider,
  tracking,
} from '@klickmarketing/react-components'
import {
  Box,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import decon from '../../assets/images/intubation/partner-decon.png'
import italic from '../../assets/images/intubation/partner-italic.png'
import breakpoint from '../../common/breakpoint'
import { BreadcrumbNav, NavBar } from '../../components/base'
import HeroBox from '../../components/Hero/HeroBox'
import Layout from '../../components/Layout/Layout'
import PhotoGrid from '../../components/PhotoGrid/PhotoGrid'
import SocialLinks from '../../components/SocialLinks/SocialLinks'

const navItems = [
  { hash: 'breaking-news', label: 'Breaking News', to: '/#breaking-news' },
  {
    hash: 'collaborations',
    label: 'Collaborations & Creations',
    to: '/#collaborations',
  },
  { hash: 'playbooks', label: 'Playbooks & Tools', to: '/#playbooks' },
  { hash: 'news', label: 'In The News', to: '/#news' },
]

const breadcrumbs = [
  { pathname: '/', label: 'COVID-19 Portal' },
  { pathname: '/#collaborations', label: 'Collaborations & Creations' },
  { label: 'Intubation Box' },
]

const photos = [
  {
    src: '6',
    alt: '3D model of North American modified design.',
    caption: '3D model of North American modified design.',
  },
  {
    src: '1',
    alt: 'Photo of COVID-19 Intubation Box being demonstrated',
    caption: 'Photo provided by Dr. Hsien Yung Lai.',
  },
  {
    src: '2',
    alt: 'Photo of COVID-19 Intubation Box being demonstrated',
    caption: 'Photo provided by Dr. Hsien Yung Lai.',
  },
  {
    src: '3',
    alt: 'Photo of COVID-19 Intubation Box being demonstrated',
    caption: 'Photo provided by Dr. Hsien Yung Lai.',
  },
  {
    src: '4',
    alt: 'Photo of COVID-19 Intubation Box being demonstrated',
    caption: 'Photo provided by Dr. Hsien Yung Lai.',
  },
  {
    src: '5',
    alt: 'Photo of COVID-19 Intubation Box being demonstrated',
    caption: 'Photo provided by Dr. Hsien Yung Lai.',
  },
  {
    src: '7',
    alt: 'Final prototype of North American design in Toronto factory.',
    caption: 'Final prototype of North American design in Toronto factory.',
  },
  {
    src: '8',
    alt: 'North American prototypes in production.',
    caption: 'North American prototypes in production.',
  },
  {
    src: '9',
    alt: 'North American prototypes in production.',
    caption: 'North American prototypes in production.',
  },
]

const BoxCreationPage = () => {
  const isMobile = useMediaQuery(match.isXS)
  const gridSpacing = isMobile ? 4 : 10

  const intubationImages = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { glob: "intubation/*.*" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  const images = reduceImages(intubationImages.allFile.edges)
  const downloadUrl =
    'https://cdn.klick.com/klick-covid19/IntubationBox_24x15x28_IP-3D-PROOF.zip'

  return (
    <Layout
      seoProps={{
        title: 'COVID-19 Intubation Box Project',
        description:
          'It is our hope to provide protection to physicians intubating patients across the Greater Toronto Area and North Eastern United States. Learn more about this partnership project and donate now.',
      }}
    >
      <HeroBox />
      <NavBar items={navItems} />
      <Container maxWidth="lg">
        <Box pt={5}>
          <BreadcrumbNav breadcrumbs={breadcrumbs} />
        </Box>
      </Container>
      <Section overflow="hidden">
        <Container maxWidth="lg">
          <Grid container spacing={gridSpacing} direction="row-reverse">
            <FadeIn from="right">
              <Grid item xs={12} md={6}>
                <Box pb={3}>
                  <GatsbyImage
                    image={images['intubation-box']}
                    alt="Medical professional using an intubation box"
                  />
                </Box>
                <Box p={4} border={1} color="black" textAlign="center">
                  <P>
                    <strong>
                      Download 3D models to modify or produce your own
                      intubation box here
                    </strong>
                  </P>
                  <P paragraph={false}>
                    <IconButton
                      component={Link}
                      role="link"
                      aria-label="Menu"
                      href={downloadUrl}
                      linkTracking={false}
                      onClick={() => {
                        tracking.track({
                          event: 'file_download',
                          eventModel: {
                            file_extension: 'zip',
                            file_name: downloadUrl.split('/').pop(),
                            track_id: 'box_page_box_model_download',
                            link_url: downloadUrl,
                          },
                        })
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </P>
                </Box>
              </Grid>
            </FadeIn>
            <FadeIn from="left">
              <Grid item xs={12} md={6}>
                <Heading variant="h4" font="primary" paragraph={true}>
                  Protecting healthcare practitioners by shielding them from
                  aerosolized <NoWrap>COVID-19</NoWrap> particles during
                  intubation.
                </Heading>
                <P>
                  As <NoWrap>COVID-19</NoWrap> cases climbed,{' '}
                  <NoWrap>Dr. Steven Bernstein</NoWrap> from Humber River
                  Hospital in Toronto came across{' '}
                  <Link
                    type="text"
                    href="https://www.taiwannews.com.tw/en/news/3902435"
                  >
                    a story
                  </Link>{' '}
                  out of Taiwan about a{' '}
                  <Link
                    type="text"
                    href="https://sites.google.com/view/aerosolbox/design"
                  >
                    simple piece of protective equipment
                  </Link>{' '}
                  that could make a difference in the fight against the
                  outbreak.{' '}
                  <Link
                    type="text"
                    href="https://www.nejm.org/doi/full/10.1056/NEJMc2007589"
                  >
                    The New England Journal of Medicine
                  </Link>{' '}
                  has just published a video showing the protective effect of
                  the intubation box.
                </P>
                <P>
                  In partnership with{' '}
                  <Link type="text" href="https://www.klick.com/">
                    Klick Health
                  </Link>{' '}
                  and{' '}
                  <Link type="text" href="https://italicpress.com/">
                    Italic Press
                  </Link>
                  , rapid localization (adjusting dimensions to match North
                  American hospital beds and body sizes), prototyping, and
                  distribution to Toronto area hospitals began within days. It
                  is our hope to provide protection to physicians intubating
                  patients across the Greater Toronto Area and North Eastern
                  United States.
                </P>
                <P>
                  This localized version of the intubation box is heat molded to
                  eliminate seams. It’s made of .25” polycarbonate and is
                  reusable when cleaned thoroughly with 70% alcohol or bleach
                  after each intubation. The box is available in two sizes: 26”
                  tall and 28” tall. Both measure 24” wide and 15” deep.
                </P>
                <P>
                  If you have the fabrication capabilities, please join us in
                  creating intubation boxes for your local hospitals. The{' '}
                  <Link
                    type="text"
                    href="https://sites.google.com/view/aerosolbox/design?authuser=0"
                  >
                    original design
                  </Link>{' '}
                  from <NoWrap>Dr. Hsien Yung Lai</NoWrap> can help get you
                  started.
                </P>
                <P>
                  <strong>
                    Thank you to our production and delivery partners:
                  </strong>
                </P>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>
                    <Link display="block" href="https://decongroup.ca/">
                      <Logo src={decon} alt="Decon logo" />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link display="block" href="https://italicpress.com/">
                      <Logo src={italic} alt="Italic logo" />
                    </Link>
                  </Grid>
                </Grid>
                <Box pt={3}>
                  <SocialLinks />
                </Box>
              </Grid>
            </FadeIn>
          </Grid>
        </Container>
      </Section>
      <BoxDonateBar />
      <Section>
        <Container maxWidth="lg">
          <PhotoGrid photos={photos} sources={images} />
        </Container>
      </Section>
    </Layout>
  )
}

const Logo = styled.img`
  display: block;
  max-width: 100px;

  ${breakpoint.sm`
    max-width: none;
  `}
`

const BoxDonateBar = () => (
  <ThemeProvider themeType="onBlack">
    <Section
      id="intudation-box-donate"
      bgcolor="black"
      color="white"
      align="center"
    >
      <FadeIn from="bottom">
        <Container maxWidth="md">
          <P>
            In this time of need, so many organizations and individuals have
            stepped up in service of their communities, checking the box on
            supportive actions from nourishing the food-insecure to applauding
            health workers daily. Will you help check off another box?
          </P>
          <P>
            One hundred percent of the proceeds will go directly to the creation
            of frontline protective equipment, with all overhead and shipping
            costs being absorbed by Klick Health and our partners. By working
            closely with our partners, we have reduced the price per intubation
            box to $105 USD. Our delivery partners are often able to deliver to
            hospitals at zero cost, ensuring the maximum donated dollars go
            straight to protecting those most in need.
          </P>
          <P>
            <strong>
              Check the box. Help us protect the people on the frontlines of{' '}
              <NoWrap>COVID-19</NoWrap>.
            </strong>
          </P>
          <Box pt={3}>
            <Button
              component={Link}
              size="xxlarge"
              href="https://torontofoundation.ca/frontline-health-foundation"
              trackId="box_page_cta_donate"
            >
              Donate
            </Button>
          </Box>
        </Container>
      </FadeIn>
    </Section>
  </ThemeProvider>
)

export default BoxCreationPage

import React from 'react'
import { FadeIn, P } from '@klickmarketing/react-components'
import { Box, Grid } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const PhotoGrid = ({ photos, sources }) => {
  return (
    <Grid container spacing={4}>
      {photos.map((photo, index) => {
        return (
          <FadeIn from="bottom" key={index}>
            <Grid item xs={12} md={4}>
              <GatsbyImage image={sources[index + 1]} alt={photo.alt} />
              {photo.caption && (
                <Box p={3} bgcolor="black">
                  <Desc paragraph={false}>{photo.caption}</Desc>
                </Box>
              )}
            </Grid>
          </FadeIn>
        )
      })}
    </Grid>
  )
}

const Desc = styled(P)`
  color: white;
`

export default PhotoGrid

import React from 'react'
import {
  Heading,
  NoWrap,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'

import Hero from './Hero'

const HeroBox = (props) => {
  return (
    <ThemeProvider themeType="onBlack">
      <Hero publicId="v1586276081/covid-19/videos/intubation-box" pt={0} pb={0}>
        <Box py={5}>
          <Heading variant="h1" align="center">
            <NoWrap>COVID-19</NoWrap>
            <br />
            Intubation Box
          </Heading>
          <Container maxWidth="sm">
            <Heading variant="h5" component="p" align="center" font="primary">
              A Frontlines.Health Foundation Project
            </Heading>
          </Container>
        </Box>
      </Hero>
    </ThemeProvider>
  )
}

export default HeroBox
